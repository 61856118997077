html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Wix MadeFor Display', sans-serif;
    /*font-family: "Roboto", system-ui;*/
    font-weight: 300;
    font-style: normal;
    /*background-color: #282C2F;*/
}

/* .App {
 text-align: center;
 height: 100vh;
 background-color: #023047;
} */

.line {
 position: absolute;
 width: 1px;
 height: 100%;
 top: 0;
 /*background: rgba(255, 255, 255, 0.1);*/
 overflow: hidden;
}

.button {
 border-radius: 2px;
 transition: all 0.2s;
 padding: 0 1rem 0.3rem 1rem;
 color: #000;
 font-size: 1.4rem;
}

.button:hover {
 color: #2F4858;
 background-color: #FFF;
}

.heading {
 color: #FFF;
}

.heading .hi {
 transition: color 0.5s ease 0.1s;
}

.heading:hover .hi{
 color: transparent;
}

.heading .name {
 transition: margin-left 0.4s ease-in 0.1s;
}

.heading:hover .name {
 margin-left: -250px;
}

.heading .period {
 color: #FFF;
 transition: color 0.2s;
}

.heading:hover .period {
 color: transparent;
}

.heading .im {
 color: #FFF;
 transition: color 0.2s ease-in 0.1s;
}

.heading:hover .im {
 color: transparent;
}

.heading .ln {
 opacity: 0;
 transition: opacity 0.2s ease-in 0.2s;
}

.heading:hover .ln {
 opacity: 100%;
}

.line::after {
 content: "";
 display: block;
 position: absolute;
 height: 15vh;
 width: 100%;
 top: -50%;
 left: 0;
 background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
 -webkit-animation: drop 7s 0s infinite;
 animation: drop 7s 0s infinite;
 -webkit-animation-fill-mode: forwards;
 animation-fill-mode: forwards;
 -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
 animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.delay {

}

.delay::after {
 animation-delay: 2.5s;
}


@keyframes drop {
 0% {
     top: -50%;
 }

 100% {
     top: 110%;
 }
}
